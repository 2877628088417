import { render, staticRenderFns } from "./configPanel.vue?vue&type=template&id=9dfa4992&scoped=true&"
import script from "./configPanel.vue?vue&type=script&lang=js&"
export * from "./configPanel.vue?vue&type=script&lang=js&"
import style0 from "./configPanel.vue?vue&type=style&index=0&id=9dfa4992&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dfa4992",
  null
  
)

component.options.__file = "configPanel.vue"
export default component.exports