/**
 * 分割线
 */
export let divider = {
  id:'',
  _id:'',
  compType: 'divider',
  ele: 'el-divider',
  compName:'分割线',
  compIcon:'divider',
  //展示表单的模式
  viewType:'component',
  config: true,
  form:false,
  //内容坐标 left|center|right
  'content-position':'left',
  text:'',
  // 控件左侧label内容
  showLabel:false,
  labelWidth: '0'
}
