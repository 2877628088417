import { render, staticRenderFns } from "./preview.vue?vue&type=template&id=b5f3dbc2&scoped=true&"
import script from "./preview.vue?vue&type=script&lang=js&"
export * from "./preview.vue?vue&type=script&lang=js&"
import style0 from "./preview.vue?vue&type=style&index=0&id=b5f3dbc2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5f3dbc2",
  null
  
)

component.options.__file = "preview.vue"
export default component.exports