import Vue from 'vue'
import VueRouter from 'vue-router'
import trainCategory from '@/views/trainManage/trainCategory'

/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

// 路由懒加载
const Login = () => import('../views/Login.vue')
const Index = () => import('../views/Index.vue')
const Welcome = () => import('../views/auth/index/Welcome.vue')
const RoleMenu = () => import('../views/auth/roleMenu/RoleMenu.vue')

const employeeManagement = () =>
  import('../views/auth/employee/EmployeeManagement.vue')
const employeeDetail = () =>
  import('../views/auth/employee/EmployeeDetail.vue')
const OrganizationManagement = () =>
  import('../views/auth/organization/OrganizationManagement.vue')
const Post = () => import('../views/auth/post/Post.vue')
const Menu = () => import('../views/auth/menu/Menu.vue')
const Authority = () => import('../views/auth/authority/Authority.vue')
const OrganizationTypeMaintenance = () =>
  import('../views/auth/organizationType/OrganizationType.vue')
const myAccountView = () => import('../views/auth/my/MyAccountView.vue')
const SystemConfig = () => import('../views/systemConfig/SystemConfig.vue')
const SystemConfigDetails = () =>
  import('../views/systemConfig/SystemConfigDetails.vue')
const TableDesign = () => import('../views/coding/tableDesign/TableDesign.vue')
const Table = () => import('../views/coding/table/Table.vue')
const TableDetails = () => import('../views/coding/table/TableDetails.vue')
const TableDdl = () => import('../views/coding/tableDdl/TableDdl.vue')
const TableDdlDetails = () => import('../views/coding/tableDdl/TableDdlDetails.vue')
// const FromDesigner = () => import('../views/fromDesigner/FromDesigner.vue')

const LoginLog = () => import('../views/loginLog/LoginLog.vue')
const LoginLogDetails = () => import('../views/loginLog/LoginLogDetails.vue')

// design
const FromDesigner = () => import('../components/formDesigner.vue')
const DialogTest = () => import('../views/fromDesigner/dialogTest.vue')
const QueryDialogTest = () => import('../views/fromDesigner/queryDialogTest.vue')
const View = () => import('../views/fromDesigner/view.vue')
const DesignTable = () => import('../views/fromDesigner/table.vue')
const AccessToken = () => import('../views/accessToken/AccessToken.vue')
const Area = () => import('../views/area/Area.vue')
const Information = () => import('../views/information/Information.vue')
const Member = () => import('../views/member/Member.vue')
const MemberCollectRecords = () => import('../views/memberCollectRecords/MemberCollectRecords.vue')
const MemberExtSubaccountConfig = () => import('../views/memberExtSubaccountConfig/MemberExtSubaccountConfig.vue')
const MemberProductRelation = () => import('../views/memberProductRelation/MemberProductRelation.vue')
const Product = () => import('../views/product/Product.vue')
const ProductDetails = () => import('../views/product/ProductDetails.vue')
const ProductCategory = () => import('../views/productCategory/ProductCategory.vue')
const ProductExtAttr = () => import('../views/productExtAttr/ProductExtAttr.vue')
const ProductExtAttrItem = () => import('../views/productExtAttrItem/ProductExtAttrItem.vue')
const ProductExtLayer = () => import('../views/productExtLayer/ProductExtLayer.vue')
const SelfCase = () => import('../views/selfCase/SelfCase.vue')
const ShoppingCar = () => import('../views/shoppingCar/ShoppingCar.vue')
const VideoCategory = () => import('../views/videoCategory/VideoCategory.vue')
const VideoCategoryProductItemRelation = () => import('../views/videoCategoryProductItemRelation/VideoCategoryProductItemRelation.vue')
const ViewRecords = () => import('../views/viewRecords/ViewRecords.vue')
const VrManage = () => import('../views/vrManage/VrManage.vue')
const ColorCardManage = () => import('../views/colorCardManage/ColorCardManage.vue')
const ColorCardManageDetails = () => import('../views/colorCardManage/ColorCardManageDetails.vue')
const coverSettings = () => import('../views/coverSettings/coverSettings.vue')
const coverSettingsDetails = () => import('../views/coverSettings/coverSettingsDetails.vue')
const MemberLoginLog = () => import('../views/memberLoginLog/MemberLoginLog.vue')
const MemberLoginLogDetails = () => import('../views/memberLoginLog/MemberLoginLogDetails.vue')
const PopupWindow = () => import('../views/popupWindow/PopupWindow.vue')
const PopupWindowDetails = () => import('../views/popupWindow/PopupWindowDetails.vue')
const VideoManage = () => import('../views/videoManage/VideoManage.vue')
const VideoBackground = () => import('../views/videoBackground/VideoBackground.vue')
const VRBackground = () => import('../views/VRBackground/VRBackground.vue')
const LibaryBackground = () => import('../views/libaryBackground/LibaryBackground.vue')
const mainPageBg = () => import('../views/mainPageBg/mainPageBg.vue')
const VrCategory = () => import('../views/vrCategory/VrCategory.vue')
const AllHouseProduct = () => import('../views/product/AllHouseProduct.vue')
const PlatformPhone = () => import('../views/platformPhone/PlatformPhone.vue')
const NoticeManage = () => import('../views/noticeManage/NoticeManage.vue')
const AccountExpirationWarning = () => import('../views/member/AccountExpirationWarning.vue')
const ExpireAccount = () => import('../views/member/ExpireAccount.vue')
const SceneryCategory = () => import('../views/sceneryManage/sceneryCategory.vue') //实景分类
const ScenerySinglePicture = () => import('../views/sceneryManage/scenerySinglePicture.vue') //单图管理
const SceneryRosiPicture = () => import('../views/sceneryManage/sceneryRosiPicture')  //套图管理
const TrainCategory = () => import('../views/trainManage/trainCategory')  // 培训分类
const TrainSinglePicture = () => import('../views/trainManage/trainSinglePicture')  //培训单图管理
const TrainRosiPicture = () => import('../views/trainManage/trainRosiPicture')  //培训套图管理
const CreativeDesignCategory = () => import('../views/creativeDesign/creativeDesignCategory')  // 创意设计分类
const CreativeDesignSinglePicture = () => import('../views/creativeDesign/creativeDesignSinglePicture')  //创意设计单图管理
const CreativeDesignRosiPicture = () => import('../views/creativeDesign/creativeDesignRosiPicture')  //创意设计套图管理

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  { path: '/login', component: Login },
  {
    path: '/index',
    component: Index,
    redirect: '/Welcome',
    children: [
      { path: '/index', component: Welcome },
      { path: '/employee', component: employeeManagement },
      { path: '/employee/details', component: employeeDetail },
      { path: '/organization', component: OrganizationManagement },
      { path: '/post', component: Post },
      { path: '/menu', component: Menu },
      { path: '/authority', component: Authority },
      { path: '/organizationType', component: OrganizationTypeMaintenance },
      { path: '/myAccountView', component: myAccountView },
      { path: '/roleMenu', component: RoleMenu },
      { path: '/systemConfig', component: SystemConfig },
      { path: '/systemConfig/details', component: SystemConfigDetails },
      { path: '/tableDesign', component: TableDesign },
      { path: '/coding', component: Table },
      { path: '/table/details', component: TableDetails },
      { path: '/tableDdl', component: TableDdl },
      { path: '/tableDdl/details', component: TableDdlDetails },
      { path: '/loginLog', component: LoginLog },
      { path: '/loginLog/details', component: LoginLogDetails },

      { path: '/fromDesigner', name: 'designer', component: FromDesigner },
      { path: '/dialog', name: 'dialogTest', component: DialogTest },
      { path: '/queryDialog', name: 'queryDialogTest', component: QueryDialogTest },
      { path: '/view', name: 'view', component: View },
      { path: '/table', name: 'table', component: DesignTable },
      { path: '/accessToken', component: AccessToken },
      { path: '/area', component: Area },
      { path: '/information', component: Information },
      { path: '/member', component: Member },
      { path: '/memberCollectRecords', component: MemberCollectRecords },
      { path: '/memberExtSubaccountConfig', component: MemberExtSubaccountConfig },
      { path: '/memberProductRelation', component: MemberProductRelation },
      { path: '/product', component: Product },
      { path: '/ProductDetails', component: ProductDetails },
      { path: '/productCategory', component: ProductCategory },
      { path: '/productExtAttr', component: ProductExtAttr },
      { path: '/productExtAttrItem', component: ProductExtAttrItem },
      { path: '/productExtLayer', component: ProductExtLayer },
      { path: '/selfCase', component: SelfCase },
      { path: '/shoppingCar', component: ShoppingCar },
      { path: '/videoCategory', component: VideoCategory },
      { path: '/videoCategoryProductItemRelation', component: VideoCategoryProductItemRelation },
      { path: '/viewRecords', component: ViewRecords },
      { path: '/vrManage', component: VrManage },
      { path: '/colorCardManage', component: ColorCardManage },
      { path: '/colorCardManage/details', component: ColorCardManageDetails },
      { path: '/coverSettings', component: coverSettings },
      { path: '/coverSettings/details', component: coverSettingsDetails },
      { path: '/memberLoginLog', component: MemberLoginLog },
      { path: '/memberLoginLog/details', component: MemberLoginLogDetails },
      { path: '/popupWindow', component: PopupWindow },
      { path: '/popupWindow/details', component: PopupWindowDetails },
      { path: '/videoManage', component: VideoManage },
      { path: '/videoBackground', component: VideoBackground },
      { path: '/VRBackground', component: VRBackground },
      { path: '/libaryBackground', component: LibaryBackground },
      { path: '/mainPageBg', component: mainPageBg },
      { path: '/vrCategory', component: VrCategory },
      { path: '/allHouseProduct', component: AllHouseProduct },
      { path: '/platformPhone', component: PlatformPhone },
      { path: '/noticeManage', component: NoticeManage },
      { path: '/nccountExpirationWarning', component: AccountExpirationWarning },
      { path: '/expireAccount', component: ExpireAccount },
      { path: '/sceneryCategory', component: SceneryCategory },
      { path: '/scenerySinglePicture', component: ScenerySinglePicture },
      { path: '/sceneryRosiPicture', component: SceneryRosiPicture },
      { path: '/trainCategory', component: TrainCategory },
      { path: '/trainSinglePicture', component: TrainSinglePicture },
      { path: '/trainRosiPicture', component: TrainRosiPicture },
      { path: '/creativeDesignCategory', component: CreativeDesignCategory },
      { path: '/creativeDesignSinglePicture', component: CreativeDesignSinglePicture },
      { path: '/creativeDesignRosiPicture', component: CreativeDesignRosiPicture },
    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    let token = '' // token保存在localstorage中

    let item = localStorage.getItem('token')
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item)
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item
    }
    if (item && item.startTime) {
      let date = new Date().getTime()
      // 如果大于就是过期了，如果小于或等于就还没过期
      let timel = date - item.startTime
      // console.log('timelength:' + timel + ',expires:' + item.expires)
      if (timel < item.expires) {
        token = item.value
      }
    }
    if (token === null || token === '') {
      // debugger
      next('/login')
    } else {
      next()
    }
  }
})

export default router
