import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userName:"",
    loginName:"",
    portalHtml:''


  },
  mutations: {
    createUserName(state, info) {
      state.userName = info
    },
    createPortalHtml(state, info) {
      state.portalHtml = info
    },
    
  },
  getters:{
    getUserName(state) {
      return state.userName
    },
    getPortalHtml(state) {
      return state.portalHtml
    },
    
  },
  actions: {

  },
  modules: {
  },
})
