var render, staticRenderFns
import script from "./dynamicTableItem.vue?vue&type=script&lang=js&"
export * from "./dynamicTableItem.vue?vue&type=script&lang=js&"
import style0 from "./dynamicTableItem.vue?vue&type=style&index=0&id=0bbe3dfa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bbe3dfa",
  null
  
)

component.options.__file = "dynamicTableItem.vue"
export default component.exports