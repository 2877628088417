import { render, staticRenderFns } from "./table.vue?vue&type=template&id=819ce80e&scoped=true&"
import script from "./table.vue?vue&type=script&lang=js&"
export * from "./table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "819ce80e",
  null
  
)

component.options.__file = "table.vue"
export default component.exports